import React, {useState, useEffect} from 'react';
import {Button, Dropdown, Collapse} from '@douyinfe/semi-ui';
import {IconMenu} from '@douyinfe/semi-icons';
import styles from './index.module.scss';
import EN from "./en.json"
import CN from "./cn.json"

const Component = () => {
    //判断浏览器语言
    const [isCN, setIsCN] = useState(navigator.language === "zh-CN");
    const [i, setLanguage] = useState(isCN ? CN : EN as any);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [whichIntelligenceCard, setWhichIntelligenceCard] = useState(1);
    const winDownloadPath = "https://github.com/shadowcz007/MixCopilot-release/releases/download/v3.7.0/MixCopilot-3.7.0-win32-x64.exe";
    const macDownloadPath = "https://github.com/shadowcz007/MixCopilot-release/releases/download/v3.7.0/MixCopilot-3.7.0-darwin-arm64.dmg";
    const macIntelDownloadPath = "https://github.com/shadowcz007/MixCopilot-release/releases/download/v3.7.0/MixCopilot-3.7.0-darwin-x64.dmg";

    //判断当前系统是什么
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const downloadPath = isMac ? macDownloadPath : winDownloadPath;

    function setALanguage() {
        console.log("setALanguage", isCN)
        if (isCN) {
            setIsCN(false);
            setLanguage(EN);
        } else {
            setIsCN(true);
            setLanguage(CN);
        }
    }

    useEffect(() => {
        // 定义一个处理窗口大小变化的函数
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        // 添加窗口大小变化的监听器
        window.addEventListener('resize', handleResize);

        // 清理函数，移除监听器
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={styles.rootFrame42}>
            <div className={styles.frame43}>
                <div className={styles.logo}>
                    <img
                        src={require('./assets/icon-128.png')}
                        className={styles.icon128}
                    />
                    <p className={styles.mIXCopilot}>MiX Copilot</p>
                </div>
                {
                    //在响应式布局下，如果宽度大于600px，显示frame2,否则显示一个button
                    windowWidth > 800 ?
                        <div className={styles.frame2}>
                            <a style={{color: "white", textDecoration: "none"}} href="#Price">{i.Price}</a>
                            <a style={{color: "white", textDecoration: "none"}} href="#Help">{i.Help}</a>
                            <a style={{color: "white", textDecoration: "none"}} target={"_blank"}
                               href="https://hci-top.notion.site/MiX-Copilot-dd9c72f835614825910908a0afaf4ae3">{i.Tutorial}</a>
                            <a style={{color: "white", textDecoration: "none"}} target={"_blank"}
                               href="https://twitter.com/MiXCopilot">Twitter</a>
                            <p style={{cursor: "pointer"}} onClick={() => setALanguage()}>{<><span
                                style={{color: isCN ? "gray" : "white"}}>En</span><span>/</span><span
                                style={{color: isCN ? "white" : "gray"}}>中</span></>}</p>
                            <Button type={"primary"}
                                    onClick={() => window.open(downloadPath, "_blank")}
                                    style={{
                                        width: "fit-content",
                                        height: "fit-content",
                                        padding: 10,
                                        backgroundColor: "#39797a",
                                        color: "white",
                                        fontWeight: "bold",
                                        fontSize: 20,
                                        borderRadius: 5
                                    }}>{i.download}</Button>
                        </div>
                        : <Dropdown
                            trigger={"click"}
                            position={"bottomRight"}
                            render={
                                <Dropdown.Menu
                                    style={{width: 200}}
                                >
                                    <Dropdown.Item
                                        onClick={() => window.location.href = "#Price"}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: 20,
                                            fontWeight: "bold"
                                        }}>{i.Price}</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        onClick={() => window.location.href = "#Help"}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: 20,
                                            fontWeight: "bold"
                                        }}>{i.Help}</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        onClick={() => window.open("https://hci-top.notion.site/MiX-Copilot-dd9c72f835614825910908a0afaf4ae3", "_blank")}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: 20,
                                            fontWeight: "bold"
                                        }}>{i.Tutorial}</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        onClick={() => window.open("https://twitter.com/MiXCopilot", "_blank")}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: 20,
                                            fontWeight: "bold"
                                        }}>Twitter</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item
                                        onClick={() => window.open(downloadPath, "_blank")}
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            fontSize: 20,
                                            fontWeight: "bold"
                                        }}>{i.download}</Dropdown.Item>
                                </Dropdown.Menu>
                            }
                        ><Button style={{marginRight: 10, marginTop: 10}}
                                 icon={<IconMenu style={{fontSize: 32, color: "white"}}/>}/></Dropdown>
                }

            </div>
            <div className={styles.frame15}>
                <img
                    src={require('./assets/logo.png')}
                    className={styles.logo1}
                />
                <p className={styles.effortlesslyTransfor}>
                    {i.Slogan}
                </p>
                <div>
                    <Button type={"primary"} className={styles.button}
                            onClick={() => window.open(downloadPath, "_blank")}>{i.mainButton}</Button>
                    <p className={styles.windows10AndMacOS123}>
                        {i.recommended}
                    </p>
                    {
                        isMac&&<a className={styles.windows10AndMacOS123} href={macIntelDownloadPath} target={"_blank"}>
                            {i.macIntel}
                        </a>
                    }
                </div>
            </div>
            <div style={{width: "90%"}}>
                <div className={styles.wrapper}>
                    <iframe
                        className={styles.iframe}
                        src={isCN?"https://player.bilibili.com/player.html?aid=579002471&bvid=BV1i64y17751&cid=1350002802&p=1&autoplay=false":"https://www.youtube.com/embed/hPUICCnubVA?si=RC3FjJ0fX6AsfmFb"}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
            <div className={styles.containerWithCard}>
                <p className={styles.mainTitle}>{i.Multi}</p>
                <div className={styles.container}>
                    <div className={styles.cards}>
                        <div className={styles.card}
                             style={{
                                 backgroundColor: whichIntelligenceCard === 1 ? "white" : "transparent",
                                 color: whichIntelligenceCard === 1 ? "black" : "white",
                                 cursor: "pointer"
                             }}
                             onClick={() => setWhichIntelligenceCard(1)}
                        >{i.I1}</div>
                        <div className={styles.card}
                             style={{
                                 backgroundColor: whichIntelligenceCard === 2 ? "white" : "transparent",
                                 color: whichIntelligenceCard === 2 ? "white" : "gray",
                                 cursor: "not-allowed"
                             }}
                            //onClick={()=>setWhichIntelligenceCard(2)}
                        >{i.I2}</div>
                    </div>
                    <div className={styles.wrapper}>
                        {
                            whichIntelligenceCard === 1 ? <iframe
                                className={styles.iframe}
                                src={isCN?"https://player.bilibili.com/player.html?aid=664081114&bvid=BV1La4y1o7M2&cid=1350161885&p=1&autoplay=false":"https://www.youtube.com/embed/KBA7O47UUKI?si=IyjBucbbWYOo-_vv"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe> : <iframe
                                className={styles.iframe}
                                src={isCN?"":""}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        }

                    </div>
                </div>
            </div>
            <div className={styles.frame21}>
                <div className={styles.frame5}>
                    <p className={styles.mainTitle}>{i.Insightful}</p>
                </div>
                <div className={styles.workflowContainer} style={{marginBottom: 20}}>
                    <div className={styles.workflow}>
                        <div className={styles.wrapper}>
                            <iframe
                                className={styles.iframe}
                                src={isCN?"https://player.bilibili.com/player.html?aid=579110575&bvid=BV1q64y1L7qn&cid=1350165365&p=1&autoplay=false":"https://www.youtube.com/embed/Du6at3C_7wo?si=0VQX4PaRxyrcv8ft"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </div>
                        <p style={{fontSize: 20, fontWeight: "normal"}}>{i.I3}</p>
                    </div>
                    <div className={styles.workflow}>
                        <div className={styles.wrapper}>
                            <iframe
                                className={styles.iframe}
                                src={isCN?"https://player.bilibili.com/player.html?aid=494003454&bvid=BV13N41177w2&cid=1350179638&p=1&autoplay=false":"https://www.youtube.com/embed/qED7MLRu3Cg?si=erzexcEhXNc9a0Hw"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </div>
                        <p style={{fontSize: 20, fontWeight: "normal"}}>{i.I4}</p>
                    </div>
                </div>
                <div className={styles.workflowContainer}>
                    <div className={styles.workflow}>
                        <div className={styles.wrapper}>
                            <iframe
                                className={styles.iframe}
                                src={isCN?"https://player.bilibili.com/player.html?aid=366569538&bvid=BV1u94y1E7ZR&cid=1350181583&p=1&autoplay=false":"https://www.youtube.com/embed/0QFJQIXRJUQ?si=XxwpBcUARkvGRqHq"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </div>
                        <p style={{fontSize: 20, fontWeight: "normal"}}>{i.I5}</p>
                    </div>
                    <div className={styles.workflow}>
                        <div className={styles.wrapper}>
                            <iframe
                                className={styles.iframe}
                                src={isCN?"https://player.bilibili.com/player.html?aid=236525736&bvid=BV1We411Z7WD&cid=1350183971&p=1&p=1&autoplay=false":"https://www.youtube.com/embed/bveO_79msLc?si=xvhdM4_O0QlzFrDh"}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </div>
                        <p style={{fontSize: 20, fontWeight: "normal"}}>{i.I6}</p>
                    </div>
                </div>
            </div>

            {/*<div className={styles.frame22}>*/}
            {/*    <div className={styles.frame5}>*/}
            {/*        <p className={styles.mainTitle}>Build Your Own Think Tank Team</p>*/}
            {/*        <p className={styles.noNeedForSpecialized}>*/}
            {/*            No Need for Specialized Knowledge or Complex Coding! Designed for*/}
            {/*            everyday users, making it easy to get started.*/}
            {/*            <br/>*/}
            {/*            Our web crawlers offer scheduled activation, ensuring a continuous*/}
            {/*            stream of data at your fingertips.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*    <div className={styles.wrapper}>*/}
            {/*        <iframe*/}
            {/*            className={styles.iframe}*/}
            {/*            src=""*/}
            {/*            title="YouTube video player"*/}
            {/*            frameBorder="0"*/}
            {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*            allowFullScreen>*/}
            {/*        </iframe>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div style={{width: "90%"}}>
                <p className={styles.mainTitle}>{i.OurAdvantages}</p>
                <div className={styles.advantage} style={{marginBottom: 40}}>
                    <div className={styles.advantageCard}>
                        {i.OurAdvantages1}
                    </div>
                    <div className={styles.advantageCard}>
                        {i.OurAdvantages3}
                    </div>
                </div>
                <div className={styles.advantage}>
                    <div className={styles.advantageCard}>
                        {i.OurAdvantages4}
                    </div>
                    <div className={styles.advantageCard}>
                        {i.OurAdvantages2}
                    </div>
                </div>
            </div>
            <div className={styles.frame25}>
                <p className={styles.mainTitle}>{i.Meet}</p>
                <div className={styles.UserCardContainer}>
                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/1.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Aiden Smith
                            </span>
                        </div>
                        <div>
                            {i.U1}
                        </div>

                    </div>
                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/2.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Sofia Martinez
                            </span>
                        </div>
                        <div>
                            {i.U2}
                        </div>

                    </div>

                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/3.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Yuxin Li
                            </span>
                        </div>
                        <div>
                            {i.U3}
                        </div>

                    </div>
                </div>

                <div className={styles.UserCardContainer}>
                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/4.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Mia Garcia
                            </span>
                        </div>
                        <div>
                            {i.U4}
                        </div>

                    </div>

                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/5.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Liam Wang
                            </span>
                        </div>
                        <div>
                            {i.U5}
                        </div>

                    </div>

                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/6.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Ethan Brown
                            </span>
                        </div>
                        <div>
                            {i.U6}
                        </div>

                    </div>

                </div>
                <div className={styles.UserCardContainer}>

                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/7.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                Emily Zhang
                            </span>
                        </div>
                        <div>
                            {i.U7}
                        </div>

                    </div>

                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/8.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                John Davis
                            </span>
                        </div>
                        <div>
                            {i.U8}
                        </div>

                    </div>

                    <div className={styles.userCard}>
                        <div style={{display: "flex", alignItems: "center", marginBottom: 10}}>
                            <img className={styles.img} src={require('./assets/pic/9.png')}/>
                            <span style={{fontWeight:"bold",fontSize:20}}>
                                David Wilson
                            </span>
                        </div>
                        <div>
                            {i.U9}
                        </div>

                    </div>

                </div>
            </div>
            <div className={styles.frame29} id={"Price"}>
                <p className={styles.mainTitle}>{i.OurService}</p>
                <div className={styles.payCards}>
                    <div className={styles.card}>
                        <p className={styles.title}>{i.Free}</p>
                        <p className={styles.subtitle}>{i.FreeMoney}</p>
                        <p className={styles.content}>&#8226; {i.C1}</p>
                        <p className={styles.content}>&#8226; {i.C2}</p>
                        <p className={styles.content}>&#8226; {i.C3}</p>
                        <p className={styles.content}>&#8226; {i.C4}</p>
                    </div>
                    <div className={styles.card}>
                        <p className={styles.specialTitle}>{i.Subscribe}</p>
                        <p className={styles.subtitle}>{i.SubscribeMoney}</p>
                        <p className={styles.content}>&#8226; {i.C1}</p>
                        <p className={styles.content}>&#8226; {i.C2}</p>
                        <p className={styles.content}>&#8226; {i.C5}</p>
                        <p className={styles.content}>&#8226; {i.C6}</p>
                        <p className={styles.content}>&#8226; {i.C7}</p>
                        <p className={styles.content}>&#8226; {i.C8}</p>
                        <p className={styles.content}>&#8226; {i.C9}</p>
                    </div>
                    <div className={styles.card}>
                        <p className={styles.specialTitle}>{i.Purchase}</p>
                        <p className={styles.subtitle}>{i.PayOnce}</p>
                        <p className={styles.content}>&#8226; {i.C1}</p>
                        <p className={styles.content}>&#8226; {i.C2}</p>
                        <p className={styles.content}>&#8226; {i.C10}</p>
                        <p className={styles.content}>&#8226; {i.C11}</p>
                        <p className={styles.content}>&#8226; {i.C12}</p>
                        <p className={styles.content}>&#8226; {i.C13}</p>
                        <p className={styles.content}>...</p>
                    </div>
                </div>
            </div>
            <div style={{width: "100%"}} id={"Help"}>
                <div style={{textAlign: "center"}}>
                    <p className={styles.mainTitle}>{i.Questions}</p>
                </div>
                <Collapse
                    className={styles.Collapse}
                >
                    <Collapse.Panel header={i.Q1} itemKey="1"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10}}>
                        <p>{i.A1}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q2} itemKey="2"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10}}>
                        <p>{i.A2}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q3} itemKey="3"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10}}>
                        <p>{i.A3}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q4} itemKey="4"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A4}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q5} itemKey="5"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A5}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q6} itemKey="6"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A6}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q7} itemKey="7"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A7}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q8} itemKey="8"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A8}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q11} itemKey="11"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A11}</p>
                    </Collapse.Panel>
                    <Collapse.Panel header={i.Q9} itemKey="9"
                                    style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>
                        <p>{i.A9}</p>
                    </Collapse.Panel>
                    {/*<Collapse.Panel header={i.Q10} itemKey="10"*/}
                    {/*                style={{borderBottom: "1px gray solid", paddingBottom: 10, paddingTop: 10}}>*/}
                    {/*    <p>{i.A10}</p>*/}
                    {/*</Collapse.Panel>*/}
                </Collapse>
            </div>
            <div className={styles.frame39}>
                <div className={styles.frame30}>
                    <p className={styles.mainTitle}>{i.IfYou}</p>
                    <div className={styles.frame4}
                         onClick={() => window.open("https://discord.gg/EKxXePt6Zx", "_blank")}>
                        <img
                            src={require('./assets/discord.png')}
                            className={styles.image3}
                        />
                        <p>{i.Community}</p>
                    </div>
                </div>
                <div className={styles.frame38}>
                    <div className={styles.frame37}>
                        <div className={styles.frame12}>
                            <img
                                src={require('./assets/icon-128.png')}
                                className={styles.icon128}
                            />
                            <p className={styles.mIXCopilot}>MiX Copilot</p>
                        </div>
                        <p className={styles.a2023MiXCopilotAllRi}>
                            © 2023 MiX Copilot. All rights reserved.
                        </p>
                    </div>
                    <div className={styles.frame36}>
                        <div className={styles.frame31} id={"downloadPath"}>
                            <p className={styles.title}>{i.download}</p>
                            <a target={"_blank"} href={winDownloadPath} className={styles.item}>Windows</a>
                            <a target={"_blank"} href={macDownloadPath} className={styles.item}>macOS-M series</a>
                            <a target={"_blank"} href={macIntelDownloadPath} className={styles.item}>macOS-Intel</a>
                        </div>
                        <div className={styles.frame31}>
                            <p className={styles.title}>{i.Product}</p>
                            <a target={"_blank"} href={"https://mix-copilot.canny.io/feature-requests"}
                               className={styles.item}>{i.Changelog}</a>
                            <a target={"_blank"} href={"https://mix-copilot.canny.io/"}
                               className={styles.item}>{i.Roadmap}</a>
                            <a target={"_blank"}
                               href="https://hci-top.notion.site/MiX-Copilot-dd9c72f835614825910908a0afaf4ae3"
                               className={styles.item}>{i.Tutorial}</a>
                        </div>
                        <div className={styles.frame31}>
                            <p className={styles.title}>{i.Contact}</p>
                            <a href={"mailto:xue160709@gmail.com"} className={styles.item}>Email</a>
                            <a target={"_blank"} href={"https://twitter.com/MiXCopilot"}
                               className={styles.item}>Twitter</a>
                            <a target={"_blank"} href={"https://discord.gg/EKxXePt6Zx"}
                               className={styles.item}>Discord</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Component;
